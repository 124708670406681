import React from "react";
import { UiScope } from "@bloomreach/ui-extension-saas";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {LocalizationProvider, MobileTimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface CmsFieldState {
    // minimumTimeTakenHours: number,
    // minimumTimeTakenMinutes: number,
    // maximumTimeTakenHours: number,
    // maximumTimeTakenMinutes: number,
    minimumTimeTaken: string,
    maximumTimeTaken: string,
    editMode: boolean
}

interface CmsFieldProperties {
    ui: UiScope
}

export default class CmsField extends React.Component<CmsFieldProperties, CmsFieldState> {

    constructor(props: CmsFieldProperties) {
        super(props);

        this.state = {
            // minimumTimeTakenHours:0,
            // minimumTimeTakenMinutes:0,
            // maximumTimeTakenHours:0,
            // maximumTimeTakenMinutes:0,
            minimumTimeTaken: "",
            maximumTimeTaken: "",
            editMode: false,
        }
    }

    componentDidMount() {
        this.getInitialItems(this.props.ui)
          .then(duration => {
                if(!this.isEmpty(duration)) {
                    this.setState({
                        // minimumTimeTakenHours: duration.minimumTimeTakenHours,
                        // minimumTimeTakenMinutes: duration.minimumTimeTakenMinutes,
                        // maximumTimeTakenHours: duration.maximumTimeTakenHours,
                        // maximumTimeTakenMinutes: duration.maximumTimeTakenMinutes,
                        minimumTimeTaken: duration.minimumTimeTaken,
                        maximumTimeTaken: duration.maximumTimeTaken,
                    });
                }
          })
    }

    isEmpty(obj:any) {
        return Object.keys(obj).length === 0;
    }

    componentDidUpdate(){
        const {
            // minimumTimeTakenHours,
            // minimumTimeTakenMinutes,
            // maximumTimeTakenHours,
            // maximumTimeTakenMinutes,
            minimumTimeTaken,
            maximumTimeTaken,
        } = this.state;

        if(minimumTimeTaken && maximumTimeTaken
          // minimumTimeTakenHours >0
          // && minimumTimeTakenMinutes >0
          // && maximumTimeTakenHours >0
          // && maximumTimeTakenMinutes >0
    ){
            this.setValue({
                // "minimumTimeTakenHours": minimumTimeTakenHours,
                // "minimumTimeTakenMinutes": minimumTimeTakenMinutes,
                // "maximumTimeTakenHours": maximumTimeTakenHours,
                // "maximumTimeTakenMinutes": maximumTimeTakenMinutes,
                "minimumTimeTaken": minimumTimeTaken,
                "maximumTimeTaken": maximumTimeTaken,
            })
        }
    }

    async getInitialItems(ui: UiScope) {
        try {
            const brDocument = await ui.document.get();
            this.setState({editMode: brDocument.mode === 'edit'});

            const duration = await ui.document.field.getValue();
            return duration? JSON.parse(duration) : {};

        } catch (error: any) {
            console.error('Failed to register extension:', error.message);
            console.error('- error code:', error.code);
        }
        return [];
    }

    async setValue(duration: any) {
        return this.props.ui.document.field.setValue(JSON.stringify(duration));
    }



    render() {
        const {
            // minimumTimeTakenHours,
            // minimumTimeTakenMinutes,
            // maximumTimeTakenHours,
            // maximumTimeTakenMinutes,
            minimumTimeTaken,
            maximumTimeTaken,
            editMode,
        } = this.state;

        return (
          <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker ampm={false}
                                  label="Minimum time taken*"
                                  value={minimumTimeTaken? dayjs(minimumTimeTaken, "HH:mm") : null}
                                  disabled={!editMode}
                                  sx={{ marginBottom: "15px" }}
                                  onChange={(newValue) => {
                                      let minimumTimeTaken = dayjs(newValue).format("HH:mm");
                                      this.setState({minimumTimeTaken: minimumTimeTaken});
                                  }}/>
              </LocalizationProvider>
              <br/>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker ampm={false}
                                    label="Maximum time taken*"
                                    value={maximumTimeTaken? dayjs(maximumTimeTaken, "HH:mm") : null}
                                    disabled={!editMode}
                                    sx={{ marginBottom: "15px" }}
                                    onChange={(newValue) => {
                                        let maximumTimeTaken = dayjs(newValue).format("HH:mm");
                                        this.setState({maximumTimeTaken: maximumTimeTaken});
                                    }}/>
              </LocalizationProvider>

              {/*<FormControl required fullWidth={true} sx={{ marginBottom: "15px" }} disabled={!editMode}>*/}
              {/*    <InputLabel id="minimum-time-taken-hours-label">Minimum Time Taken Hours</InputLabel>*/}
              {/*    <Select*/}
              {/*      labelId="minimum-time-taken-hours-label"*/}
              {/*      id="minimum-time-taken-hours"*/}
              {/*      value={minimumTimeTakenHours}*/}
              {/*      label="Minimum Time Taken Hours"*/}
              {/*      onChange={event=>{*/}
              {/*          this.setState({minimumTimeTakenHours: event.target.value as number})*/}
              {/*      }}*/}
              {/*    >*/}
              {/*        <MenuItem value={0}>0</MenuItem>*/}
              {/*        <MenuItem value={1}>1</MenuItem>*/}
              {/*        <MenuItem value={2}>2</MenuItem>*/}
              {/*        <MenuItem value={3}>3</MenuItem>*/}
              {/*        <MenuItem value={4}>4</MenuItem>*/}
              {/*        <MenuItem value={5}>5</MenuItem>*/}
              {/*        <MenuItem value={6}>6</MenuItem>*/}
              {/*        <MenuItem value={7}>7</MenuItem>*/}
              {/*        <MenuItem value={8}>8</MenuItem>*/}
              {/*        <MenuItem value={9}>9</MenuItem>*/}
              {/*        <MenuItem value={10}>10</MenuItem>*/}
              {/*        <MenuItem value={11}>11</MenuItem>*/}
              {/*        <MenuItem value={12}>12</MenuItem>*/}
              {/*    </Select>*/}
              {/*</FormControl>*/}

              {/*<FormControl required fullWidth={true} sx={{ marginBottom: "15px" }} disabled={!editMode}>*/}
              {/*    <InputLabel id="minimum-time-taken-minutes-label">Minimum Time Taken Minutes</InputLabel>*/}
              {/*    <Select*/}
              {/*      labelId="minimum-time-taken-minutes-label"*/}
              {/*      id="minimum-time-taken-minutes"*/}
              {/*      value={minimumTimeTakenMinutes}*/}
              {/*      label="Minimum Time Taken Minutes"*/}
              {/*      onChange={event=>{*/}
              {/*          this.setState({minimumTimeTakenMinutes: event.target.value as number})*/}
              {/*      }}*/}
              {/*    >*/}
              {/*        <MenuItem value={0}>0</MenuItem>*/}
              {/*        <MenuItem value={15}>15</MenuItem>*/}
              {/*        <MenuItem value={30}>30</MenuItem>*/}
              {/*        <MenuItem value={45}>45</MenuItem>*/}
              {/*    </Select>*/}
              {/*</FormControl>*/}

              {/*<FormControl required fullWidth={true} sx={{ marginBottom: "15px" }} disabled={!editMode}>*/}
              {/*    <InputLabel id="maximum-time-taken-hours-label">Maximum Time Taken Hours</InputLabel>*/}
              {/*    <Select*/}
              {/*      labelId="maximum-time-taken-hours-label"*/}
              {/*      id="maximum-time-taken-hours"*/}
              {/*      value={maximumTimeTakenHours}*/}
              {/*      label="Maximum Time Taken Hours"*/}
              {/*      onChange={event=>{*/}
              {/*          this.setState({maximumTimeTakenHours: event.target.value as number})*/}
              {/*      }}*/}
              {/*    >*/}
              {/*        <MenuItem value={0}>0</MenuItem>*/}
              {/*        <MenuItem value={1}>1</MenuItem>*/}
              {/*        <MenuItem value={2}>2</MenuItem>*/}
              {/*        <MenuItem value={3}>3</MenuItem>*/}
              {/*        <MenuItem value={4}>4</MenuItem>*/}
              {/*        <MenuItem value={5}>5</MenuItem>*/}
              {/*        <MenuItem value={6}>6</MenuItem>*/}
              {/*        <MenuItem value={7}>7</MenuItem>*/}
              {/*        <MenuItem value={8}>8</MenuItem>*/}
              {/*        <MenuItem value={9}>9</MenuItem>*/}
              {/*        <MenuItem value={10}>10</MenuItem>*/}
              {/*        <MenuItem value={11}>11</MenuItem>*/}
              {/*        <MenuItem value={12}>12</MenuItem>*/}
              {/*    </Select>*/}
              {/*</FormControl>*/}

              {/*<FormControl required fullWidth={true} sx={{ marginBottom: "15px" }} disabled={!editMode}>*/}
              {/*    <InputLabel id="maximum-time-taken-minutes-label">Maximum Time Taken Minutes</InputLabel>*/}
              {/*    <Select*/}
              {/*      labelId="maximum-time-taken-minutes-label"*/}
              {/*      id="maximum-time-taken-minutes"*/}
              {/*      value={maximumTimeTakenMinutes}*/}
              {/*      label="Maximum Time Taken Minutes"*/}
              {/*      onChange={event=>{*/}
              {/*          this.setState({maximumTimeTakenMinutes: event.target.value as number})*/}
              {/*      }}*/}
              {/*    >*/}
              {/*        <MenuItem value={0}>0</MenuItem>*/}
              {/*        <MenuItem value={15}>15</MenuItem>*/}
              {/*        <MenuItem value={30}>30</MenuItem>*/}
              {/*        <MenuItem value={45}>45</MenuItem>*/}
              {/*    </Select>*/}
              {/*</FormControl>*/}
          </>
        );
    }
}


